// ... existing imports ...
import React, { useState } from 'react';
import { Modal, Button, Form, Alert } from 'react-bootstrap';
import { createDozerCoin } from '../../../apis/atlantis.api'; // Import the function from dozer.api.js

const CreateDozerCoinsModal = ({ show, onHide, token }) => {
  const [walletAddress, setWalletAddress] = useState('');
  const [coinAmount, setCoinAmount] = useState(null);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);
  const [failedWallets, setFailedWallets] = useState([]); // Change to an array for multiple failed wallets

  const delay = ms => new Promise(resolve => setTimeout(resolve, ms)); // Helper function for delay

  const handleReward = async () => {
    setLoading(true);
    setSuccess(false);
    setError(null); // Reset error state at the start of the function

    let currWallet;
    try {
      const wallets = JSON.parse(walletAddress); // Parse the JSON input
      if (!Array.isArray(wallets)) {
        setError('Input must be a JSON array'); // Set error for non-array input
        return; // Stop execution if input is not a JSON array
      }
      if (coinAmount <= 0 || coinAmount > 10) {
        setError('Coin amount must be between 0.001 and 10');
        return;
      }
      for (const wallet of wallets) {
        currWallet = wallet;
        const response = await createDozerCoin(wallet, coinAmount * 1000, token); // Call the function for each wallet
        await delay(300);
        if (!response?.id) {
          setFailedWallets(wallets.slice(wallets.indexOf(wallet))); // Store all subsequent wallets
          break; // Stop on the first failure
        }
        console.log(`${wallet} success id: ${response?.id}`);
      }
      setSuccess(true); // If all succeed, set success
    } catch (e) {
      console.log(e);
      setError(currWallet ?? e);
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setWalletAddress(''); // Reset wallet address
    setCoinAmount(null); // Reset coin amount
    setFailedWallets([]); // Clear failed wallets
    setSuccess(false); // Reset success state
    setError(null); // Reset error state
    onHide(); // Call the onHide function
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Reward Dozer Coin</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {success && (
          <Alert variant="success" onClose={() => setSuccess(false)} dismissible>
            Dozer Coin successfully dropped!
          </Alert>
        )}
        <Form>
          <Form.Group controlId="walletAddresses">
            <Form.Label>Wallet Addresses (comma-separated)</Form.Label>
            <Form.Control
              as="textarea"
              placeholder='Paste wallet IDs as a JSON array, e.g. ["8UNuw6Wv8F47ymuCdZxzN29fK5ETafoyz2ih9YYAozVw", ...]'
              value={walletAddress}
              style={{
                height: '300px',
                overflowY: 'auto', // Enable vertical scrolling
                overflowX: 'hidden', // Prevent horizontal scrolling
                whiteSpace: 'pre-wrap', // Ensure text wraps within the textarea
                resize: 'none',
                borderColor: failedWallets.length > 0 ? 'red' : undefined,
              }}
              onChange={e => setWalletAddress(e.target.value)}
              required
            />
            {failedWallets.length > 0 && (
              <div style={{ marginTop: '10px', color: 'red' }}>
                <strong>Failed Wallets:</strong>
                <ul style={{ listStyleType: 'none', padding: '0' }}>
                  {failedWallets.map((wallet, index) => (
                    <li key={index} style={{ backgroundColor: 'yellow' }}>
                      {wallet}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </Form.Group>
          {error && (
            <div style={{ marginTop: '10px', color: 'red' }}>
              <strong>Error:</strong> {error.message || String(error)}
            </div>
          )}
          <Form.Group controlId="coinAmount" className="mt-3">
            <Form.Label>Coin Amount</Form.Label>
            <Form.Control
              type="number"
              min="0.001" // Minimum value
              max="10" // Maximum value
              step="0.001" // Step value for decimal input
              placeholder="Enter Dozer Coin Amount in Dozer bet size (0.001 ~ 1)"
              value={coinAmount !== null && coinAmount !== undefined ? coinAmount : ''} // Ensure value is a valid number or empty string
              onChange={e => {
                const value = parseFloat(e.target.value);
                setCoinAmount(isNaN(value) ? 0 : value); // Store the decimal value, default to 0 if NaN
              }}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleReward} disabled={loading || coinAmount <= 0}>
          {loading ? 'Loading...' : 'Reward'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CreateDozerCoinsModal;
