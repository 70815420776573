// Import React and necessary hooks
import React, { useEffect, useState, useRef } from 'react';
// Import the modal component
import CreateDozerCoinsModal from './CreateDozerCoinsModal';
import { useAuth } from '../../../contexts/auth.context';

// DozerCoin component
const DozerCoin = () => {
  const [showModal, setShowModal] = useState(false);
  const { auth } = useAuth();

  const authToken = useRef(auth?.idToken); // Create a ref for the auth?.idToken

  useEffect(() => {
    authToken.current = auth?.idToken; // Update the ref with the latest value of auth?.idToken
  }, [auth?.idToken]);

  return (
    <div className="container pt-3" style={{ overflow: 'scroll' }}>
      <h4>Reward Dozer Coin</h4>
      <button type="button" className="btn btn-primary mb-4" onClick={() => setShowModal(true)}>
        Create Dozer Coin
      </button>
      <CreateDozerCoinsModal
        show={showModal}
        token={authToken.current}
        onHide={() => setShowModal(false)}
      />
    </div>
  );
};

// Export the DozerCoin component
export default DozerCoin;
